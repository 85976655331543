import React, {
  Fragment,
  useMemo,
  useState,
} from 'react'
  
  import {
    Icon,
  } from "@shopify/polaris"
  
  import {
    DesktopMajor,
    MobileMajor
  } from '@shopify/polaris-icons';
  import CartPagePreview from './cartPagePreview'
  import CartPopupPreviewV1 from './cart/v1/cartPopupPreview'
  import Divider from '../../../components/divider'
  
  function CartPreview(props) {
    const [toggleValue, setToggleValue] = useState("Desktop")
    const locale = props.shopData && props.shopData.shopData && props.shopData.shopData.primary_locale || "en-US";
    const currency = props.shopData && props.shopData.shopData && props.shopData.shopData.currency || "USD";
    const [loading, setLoading] = useState(false)
    const [variantID, setVariantID] = useState(props.variants ? props.variants[0].id: null)
    const addToCart = () => {
      setLoading(true);
      setTimeout(function(){
        setLoading(false);
      }.bind(this),1000);  
    }

    const renderPreview = useMemo(() => {
      if (props?.cartType && props.cartType === 'page') {
        return <CartPagePreview {...props} locale={locale} currency={currency} loading={loading} addToCart={addToCart} variantID={variantID} setVariantID={setVariantID} type="preview"/>
      } else {
        return <CartPopupPreviewV1 {...props} locale={locale} currency={currency} loading={loading} addToCart={addToCart} variantID={variantID} setVariantID={setVariantID} type="preview"/>
      }
    }, [props]);

    return (
      <Fragment>
        <div style={{display:"flex", flexDirection: "row", 	alignItems: "center", marginBottom: 15}}>
          <div style={{ display:"flex",  flexDirection: "row"}}>
            <div onClick={ () => { setToggleValue("Desktop") } } style={"Desktop" === toggleValue ? { borderRadius:'4px 0 0 4px', padding: '5px', borderRight:'none', background: '#A3A3A3', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)', cursor: 'pointer'} : {border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius:'4px 0 0 4px', padding: '5px', borderRight:'none', cursor: 'pointer'}}>
              <Icon source={DesktopMajor} color="base"/>
            </div>
            <div onClick={ () => { setToggleValue("Mobile") } } style={"Mobile" === toggleValue ? { borderRadius:'0 4px 4px 0', padding: '5px', borderLeft:'none', background: '#A3A3A3', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)', cursor: 'pointer'} : {border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius:'0 4px 4px 0', padding: '5px', borderLeft:'none', cursor: 'pointer'}}>
              <Icon source={MobileMajor} color="base"/>
            </div>
          </div>
          <div style={{textAlign: 'center', width: '100%'}}>
            <p style={{
              textAlign: 'center',
              color: '#aaa',
              fontSize: 13,
            }}>{"Desktop" === toggleValue ? "Desktop cart preview":"Mobile cart preview"}</p>
          </div>
        </div>  
        <Divider />
        {"Desktop" === toggleValue ?
          <div style={{position: 'relative'}}>
            {renderPreview}
          </div>
          :
          <div style={{ position: 'relative',padding:'0'}}>
            {renderPreview}
          </div>
        }
      </Fragment>
    )
  }
  
  export default CartPreview;